import React from 'react';
import Paper from '@material-ui/core/Paper';
import { compose, withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import cs from '../../const.js';
import 'react-image-gallery/styles/css/image-gallery.css';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

var format = require('string-format');
const URL = cs.BaseURL + '/order/detail';

class OrderRefInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = { orderCode: this.props.orderCode, order: null, infos: [] };
    }
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    queryString() {
        const { orderCode } = this.state;
        let queryString = `${URL}?order_code=${orderCode}`;
        return queryString;
    }

    loadData = conditions => {
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-merchant-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    var order = data.data;

                    var objs = [];
                    if (order.reference_info) {
                        try {
                            var infos = JSON.parse(order.reference_info.toString());

                            if (infos && Array.isArray(infos)) {
                                for (var i = 0; i < infos.length; i++) {
                                    objs.push({
                                        caption:
                                            infos[i].caption != null &&
                                            infos[i].caption != undefined
                                                ? infos[i].caption.toString()
                                                : '',
                                        value:
                                            infos[i].value != null && infos[i].value != undefined
                                                ? infos[i].value.toString()
                                                : ''
                                    });
                                }
                            }
                        } catch (er) {
                            objs = [];
                        }
                    }
                    this.setState({
                        order: data.data,
                        infos: objs,
                        loading: false
                    });
                } else {
                    this.setState({
                        order: null,
                        infos: [],
                        loading: false
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => this.setState({ loading: false }));
    };

    render() {
        const { order, infos } = this.state;
        const { classes } = this.props;

        return (
            <div>
                <List>
                    <ListItem
                        style={{
                            flexGrow: 1,
                            width: '100%',
                            display: 'block'
                        }}
                    >
                        {infos &&
                            infos.map(item => (
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'inline-block'
                                    }}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="first-start"
                                        alignItems="stretch"
                                        spacing={16}
                                    >
                                        <Grid item xs={3}>
                                            <ListItemText
                                                primary={
                                                    <Typography component="body2">
                                                        {item.caption != undefined
                                                            ? item.caption
                                                            : ''}
                                                    </Typography>
                                                }
                                                style={{ alignItems: 'left', padding: 0 }}
                                            />
                                        </Grid>

                                        <Grid item xs={9}>
                                            <ListItemText
                                                primary={
                                                    // <Typography variant="button">
                                                    <Typography component="body2">
                                                        {item.value != undefined
                                                            ? item.value
                                                            : ''}
                                                    </Typography>
                                                }
                                                style={{
                                                    alignContent: 'left',
                                                    marginTop: 5,
                                                    whiteSpace: 'pre-line'
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Divider style={{ margin: '10px 0 10px 0' }} />
                                </div>
                            ))}
                    </ListItem>
                </List>
            </div>
        );
    }
}

const styles = theme => ({
    inner_container: {
        padding: 20
    }
});
export default withStyles(styles)(OrderRefInfo);
