import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import 'react-image-gallery/styles/css/image-gallery.css';
import Typography from '@material-ui/core/Typography';
import MonthlySummary from './MonthlySummary';
import MonthlySummaryByProduct from './MonthlySummaryByProduct';

class MonthlyDashboard extends React.Component {
    render() {
        return (
            <div>
                <Typography variant="h5" gutterBottom component="h2" style={{ marginTop: 10 }}>
                    Tổng hợp tháng
                </Typography>
                <MonthlySummary />
                <MonthlySummaryByProduct />
            </div>
        );
    }
}

const styles = theme => ({});
export default withStyles(styles)(MonthlyDashboard);
