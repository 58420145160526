import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from '../../const.js';

import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import moment from 'moment-timezone';

import format from 'string-format';

import React from 'react';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { blue } from '@material-ui/core/colors';

// const URL = cs.BaseURL + '/report/monthly_detail';

const URL = cs.BaseURL + '/report/monthly_summary';

function formatValue(value) {
    if (value && value.report_date)
        value.report_date = moment
            .unix(value.report_date)
            .tz('Asia/Ho_Chi_Minh')
            .format('MM/YYYY');
    if (value.created_qty == 0 || value.created_qty == '0') value.created_qty = '-';
    if (value.canceled_qty == 0 || value.canceled_qty == '0') value.canceled_qty = '-';
    if (value.agent_submitted_qty == 0 || value.agent_submitted_qty == '0') value.agent_submitted_qty = '-';
    if (value.op_approved_qty == 0 || value.op_approved_qty == '0') value.op_approved_qty = '-';
    if (value.op_rejected_qty == 0 || value.op_rejected_qty == '0') value.op_rejected_qty = '-';
    if (value.mc_approved_qty == 0 || value.mc_approved_qty == '0') value.mc_approved_qty = '-';
    if (value.mc_rejected_qty == 0 || value.mc_rejected_qty == '0') value.mc_rejected_qty = '-';
    if (value.internal_sla_avg == 0 || value.internal_sla_avg == '0' || !parseInt(value.internal_sla_avg))
        value.internal_sla_avg = '-';
    else {
        var hours = parseInt(parseInt(value.internal_sla_avg) / 60);
        var minutes = parseInt(value.internal_sla_avg) - hours * 60;
        if (hours > 0) value.internal_sla_avg = format('{0}h{1}p', hours, minutes);
        else value.internal_sla_avg = format('{0}p', minutes);
    }
    if (value.external_sla_avg == 0 || value.external_sla_avg == '0' || !parseInt(value.value)) value.external_sla_avg = '-';
    else {
        var hours = parseInt(parseInt(value.external_sla_avg) / 60);
        var minutes = parseInt(value.external_sla_avg) - hours * 60;
        if (hours > 0) value.external_sla_avg = format('{0}h {1}p', hours, minutes);
        else value.external_sla_avg = format('{0}p', minutes);
    }

    return value;
}

class MonthlySummaryByProduct extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                { name: 'report_date', title: 'Tháng' },
                { name: 'mc_product_name', title: 'Sản phẩm' },
                { name: 'created_qty', title: 'Tiếp nhận' },
                // { name: 'canceled_qty', title: 'Hủy' },
                { name: 'agent_submitted_qty', title: 'Xử lý' },
                { name: 'op_approved_qty', title:  'Hoàn tất' },
                // { name: 'op_rejected_qty', title: 'OP từ chối' },
                { name: 'mc_approved_qty', title: 'Phê duyệt' },
                { name: 'mc_rejected_qty', title: 'Từ chối' }
                // { name: 'internal_sla_avg', title: 'In-SLA' },
                // { name: 'external_sla_avg', title: 'Ex-SLA' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'report_date', align: 'center', width: 150 },
                { columnName: 'mc_product_name', align: 'center' },
                { columnName: 'created_qty', align: 'center', width: 120 },
                // { columnName: 'canceled_qty', align: 'center', width: 120 },
                {
                    columnName: 'agent_submitted_qty',
                    align: 'center',
                    width: 60
                },
                { columnName: 'op_approved_qty', align: 'center', width: 120 },
                // { columnName: 'op_rejected_qty', align: 'center', width: 120 },
                { columnName: 'mc_approved_qty', align: 'center', width: 120 },
                { columnName: 'mc_rejected_qty', align: 'center', width: 120 }
                // { columnName: 'internal_sla_avg', align: 'center', width: 60 },
                // { columnName: 'external_sla_avg', align: 'center', width: 60 }
            ],
            // pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 10,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'report_date', direction: 'desc' }]
        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
    }
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }
    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }
    changeSorting(sorting) {
        this.setState(
            {
                loading: true,
                sorting: sorting
            },
            () => {
                this.loadData();
            }
        );
    }

    queryString() {
        const { pageSize, currentPage, sorting } = this.state;

        let queryString = `${URL}?pagesize=${pageSize}&page=${currentPage + 1}&group_field1=mc_product_code&orderby=report_date&asc=false`;

        const columnSorting = sorting[0];
        if (columnSorting) {
            queryString = `${queryString}&orderby=${columnSorting.columnName}`;

            if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
            else queryString = `${queryString}&asc=true`;
        }

        return queryString;
    }
    loadData = conditions => {
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-merchant-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                }
            })
            .catch(() => this.setState({ loading: false }));
    };

    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                this.loadData();
            }
        );
    };

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        return (
            <div>
                <Paper className={classes.grid_Container}>
                    <Grid rows={rows} columns={columns} getRowId={getRowId}>
                        {/* <SortingState sorting={sorting} onSortingChange={this.changeSorting} /> */}
                        <PagingState
                            currentPage={currentPage}
                            onCurrentPageChange={this.changeCurrentPage}
                            onPageSizeChange={this.changePageSize}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={totalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            rowComponent={RowComponent}
                            messages={tableMessages}
                            height="600"
                        />
                        <TableHeaderRow
                            // showSortingControls
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>
            </div>
        );
    }
}

const getRowId = row => row.report_date + row.mc_product_code;
MonthlySummaryByProduct.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: 1,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit * 2
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 200,
        minWidth: 120
    },
    search_select_menu: {
        width: 200
    },
    grid_header: {
        // backgroundColor: blue
    }
});

const RowComponent = props => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};
const HeaderRowComponent = props => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = props => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                alignContent: 'center'
            }}
        />
    );
};

const tableMessages = {
    noData: 'Không có dữ liệu'
};

const pagingPanelMessages = {
    showAll: 'Hiển thị tất cả',
    rowsPerPage: 'Số dòng mỗi trang',
    info: '{from} đến {to} / Tổng : {count}'
};

const TableComponentBase = ({ classes, ...restProps }) => <VirtualTable.Table {...restProps} className={classes.tableStriped} />;

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withStyles(styles)(MonthlySummaryByProduct);
