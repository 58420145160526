import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { ToastProvider } from 'react-toast-notifications';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';

import DailyDashboard from './reports/DailyDashboard';
import MonthlyDashboard from './reports/MonthlyDashboard';

import AllOrders from './orders/AllOrders';
import NewOrders from './orders/NewOrders';
import AllocatedOrders from './orders/AllocatedOrders';
import WaitingForApproveOrders from './orders/WaitingForApproveOrders';
import ApprovedOrders from './orders/ApprovedOrders';
import SupplementRequestOrders from './orders/SupplementRequestOrders';
import RejectedOrders from './orders/RejectedOrders';
import CanceledOrders from './orders/CanceledOrders';

import OrderDetail from './order/OrderDetail';
import CreateOrder from './order/CreateOrder';
import ImportOrder from './order/ImportOrder';

import ChangePassword from './auth/ChangePassword';

import cs from '../const.js';
const URL = cs.BaseURL + '/user/detail';
const revokeTokenURL = cs.BaseURL + '/auth/logout';
const drawerWidth = 240;
const mainListItems = (
    <div>
        <ListItem button component={Link} to="/report/daily">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    bar_chart
                </Icon>
            </ListItemIcon>

            <ListItemText primary="Thống kê ngày" />
        </ListItem>
        <ListItem button component={Link} to="/report/monthly">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    bar_chart
                </Icon>
            </ListItemIcon>
            <ListItemText primary="Thống kê tháng" />
        </ListItem>
        <ListItem button component={Link} to={{ pathname: '/all', state: '123' }}>
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    search
                </Icon>
            </ListItemIcon>
            <ListItemText primary="Tra cứu" />
        </ListItem>
        <ListItem button component={Link} to="/new">
            <ListItemIcon>
                <Icon
                    className="fa fa-file "
                    color="disable"
                    style={{ marginLeft: 12, fontSize: 23 }}
                />
            </ListItemIcon>
            <ListItemText primary="Chưa phân bổ" />
        </ListItem>

        <ListItem button component={Link} to="/allocated">
            <ListItemIcon>
                <Icon
                    className="fa fa-file "
                    color="disable"
                    style={{ marginLeft: 12, fontSize: 23 }}
                />
            </ListItemIcon>
            <ListItemText primary="Đang thực hiện" />
        </ListItem>
        <Divider variant="middle" />
        <ListItem button component={Link} to="/waiting">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    query_builder
                </Icon>
            </ListItemIcon>
            <ListItemText primary="Chờ phê duyệt" />
        </ListItem>
        <ListItem button component={Link} to="/approved">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    check_circle
                </Icon>
            </ListItemIcon>
            <ListItemText primary="Đã phê duyệt" />
        </ListItem>
        <ListItem button component={Link} to="/supplementRequest">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    update
                </Icon>
            </ListItemIcon>
            <ListItemText primary="Yêu cầu bổ sung" />
        </ListItem>
        <ListItem button component={Link} to="/rejected">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                    cancel
                </Icon>
            </ListItemIcon>
            <ListItemText primary="Đã từ chối" />
        </ListItem>
        <Divider variant="middle" />
        {/* <ListItem button component={Link} to="/canceled">
            <ListItemIcon>
                <Icon color="disable" style={{ marginLeft: 5, fontSize: 28 }}>
                    delete_forever
                </Icon>
            </ListItemIcon>
            <ListItemText primary="Đã hủy" />
        </ListItem> */}
        {/* <Divider variant="middle" /> */}
    </div>
);
const styles = (theme) => ({
    root: {
        display: 'flex',
        marginTop: 0,
        paddingTop: 10
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: 'rgb(68, 126, 236)'
    },
    toolbarHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 2px',
        ...theme.mixins.toolbar
    },
    username: {
        marginRight: 1,
        // paddingLeft: 5,
        // paddingRight: 5,
        textAlign: 'right',
        fontWeight: 550,
        fontSize: 11
    },
    avatar: {
        marginRight: 6
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    menuButtonHidden: {
        display: 'none'
    },
    title: {
        flexGrow: 1
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        paddingTop: 0,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        height: '100vh',
        overflow: 'auto'
    },
    chartContainer: {
        marginLeft: -22
    },
    tableContainer: {
        height: 320
    },
    h5: {
        marginBottom: theme.spacing.unit * 2
    }
});

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            open: true,
            showCreateOrder: false,
            showImportOrder: false,
            showChangePassword: false
        };

        this.handleShowCreateOrder = this.handleShowCreateOrder.bind(this);
        this.handleCloseCreateOrder = this.handleCloseCreateOrder.bind(this);
        this.handleShowImportOrder = this.handleShowImportOrder.bind(this);
        this.handleCloseImportOrder = this.handleCloseImportOrder.bind(this);
        this.handleShowChangePassword = this.handleShowChangePassword.bind(this);
        this.handleCloseChangePassword = this.handleCloseChangePassword.bind(this);
        this.loadData();
    }

    loadData() {
        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-merchant-user');
        if (user) username = JSON.parse(String(user)).name;
        if (!username) return;
        let queryString = `${URL}?name=${username}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-merchant-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({
                        user: data.data,
                        loading: false
                    });
                } else {
                    this.setState({
                        user: {},
                        loading: false
                    });
                }
            })
            .catch(() => this.setState({ loading: false }));
    }
    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };
    handleLogOut = () => {
        let queryString = `${revokeTokenURL}`;
        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-merchant-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            })
            .catch(() => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            });

        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
    };

    handleShowImportOrder = () => {
        this.setState({ showImportOrder: true });
    };
    handleCloseImportOrder = () => {
        this.setState({ showImportOrder: false });
    };

    handleShowCreateOrder = () => {
        this.setState({ showCreateOrder: true });
    };
    handleCloseCreateOrder = () => {
        this.setState({ showCreateOrder: false });
    };

    handleShowChangePassword = () => {
        this.setState({ showChangePassword: true });
    };
    handleCloseChangePassword = () => {
        this.setState({ showChangePassword: false });
    };

    render() {
        const { classes } = this.props;

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-merchant-user');
        if (user) username = JSON.parse(String(user)).fullname;
        if (!username) username = JSON.parse(String(user)).name;

        const DailyDashboardArea = () => (
            <div>
                <Typography component="div" className={classes.tableContainer}>
                    <DailyDashboard />
                </Typography>
            </div>
        );

        const MonthlyDashboardArea = () => (
            <div>
                <Typography component="div" className={classes.tableContainer}>
                    <MonthlyDashboard />
                </Typography>
            </div>
        );

        const AllOrdersArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Tra cứu hồ sơ
                </Typography>
                <div className={classes.tableContainer}>
                    <AllOrders />
                </div>
            </div>
        );
        const NewOrdersArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Chưa phân bổ
                </Typography>
                <div className={classes.tableContainer}>
                    <NewOrders />
                </div>
            </div>
        );
        const AllocatedOrdersArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Đang thực hiện
                </Typography>
                <div className={classes.tableContainer}>
                    <AllocatedOrders />
                </div>
            </div>
        );

        const WaitingForApproveArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Hồ sơ chờ phê duyệt
                </Typography>
                <div className={classes.tableContainer}>
                    <WaitingForApproveOrders />
                </div>
            </div>
        );

        const ApprovedOrdersArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Hồ sơ đã phê duyệt
                </Typography>
                <div className={classes.tableContainer}>
                    <ApprovedOrders />
                </div>
            </div>
        );

        const SupplementRequestOrdersArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Hồ sơ yêu cầu bổ sung
                </Typography>
                <div className={classes.tableContainer}>
                    <SupplementRequestOrders />
                </div>
            </div>
        );
        const RejectedOrdersArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Hồ sơ đã từ chối
                </Typography>
                <div className={classes.tableContainer}>
                    <RejectedOrders />
                </div>
            </div>
        );

        // const CanceledOrdersArea = () => (
        //     <div>
        //         <Typography variant="h5" gutterBottom component="h2">
        //             Hồ sơ đã hủy
        //         </Typography>
        //         <div className={classes.tableContainer}>
        //             <CanceledOrders />
        //         </div>
        //     </div>
        // );

        return (
            <Router>
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar
                        position="absolute"
                        className={classNames(
                            classes.appBar,
                            this.state.open && classes.appBarShift
                        )}
                    >
                        <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(
                                    classes.menuButton,
                                    this.state.open && classes.menuButtonHidden
                                )}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                variant="h5"
                                color="inherit"
                                noWrap
                                className={classes.title}
                            >
                                Hệ Thống Quản Trị Dành Cho Đối Tác
                            </Typography>

                            {/* {this.state.user && this.state.user.order_importable ? (
                                <div>
                                    <Button
                                        color="inherit"
                                        iconStyle={{
                                            height: 200,
                                            width: 200,
                                            fontSize: '48px'
                                        }}
                                        aria-label="Import hồ sơ mới"
                                        onClick={this.handleShowImportOrder}
                                    >
                                        <Icon>cloud_upload</Icon>
                                        <span style={{ marginLeft: 10 }}>Import</span>
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )} */}

                            {this.state.user && this.state.user.order_createable ? (
                                <div>
                                    <Button
                                        color="inherit"
                                        iconStyle={{
                                            height: 200,
                                            width: 200,
                                            fontSize: '48px'
                                        }}
                                        aria-label="Tạo hồ sơ mới"
                                        onClick={this.handleShowCreateOrder}
                                    >
                                        <Icon>create_new_folder</Icon>
                                        <span style={{ marginLeft: 10 }}>Tạo mới</span>
                                    </Button>
                                </div>
                            ) : (
                                    ''
                                )}

                            <Button
                                color="inherit"
                                iconStyle={{
                                    height: 200,
                                    width: 200,
                                    fontSize: '48px'
                                }}
                                aria-label="Đổi mật khẩu"
                                onClick={this.handleShowChangePassword}
                            >
                                <Icon>vpn_key</Icon>
                                <span style={{ marginLeft: 10 }}>Đổi mật khẩu</span>
                            </Button>

                            <Button
                                color="inherit"
                                iconStyle={{
                                    height: 200,
                                    width: 200,
                                    fontSize: '48px'
                                }}
                                aria-label="Đăng xuất"
                                onClick={this.handleLogOut}
                            >
                                <Icon>exit_to_app</Icon>
                                <span style={{ marginLeft: 10 }}>Đăng xuất</span>
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(
                                classes.drawerPaper,
                                !this.state.open && classes.drawerPaperClose
                            )
                        }}
                        open={this.state.open}
                    >
                        <div className={classes.toolbarHeader}>
                            {cs.System_Id === 1 ? (
                                <Avatar
                                    className={classes.avatar}
                                    src={require('../img/logo-app-trans.png')}
                                />
                            ) : (
                                    <Avatar
                                        className={classes.avatar}
                                        src={require('../img/logo-small.png')}
                                    />
                                )}
                            <Typography variant="body" className={classes.username}>
                                {username}
                            </Typography>
                            <IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <Divider />
                        <List>{mainListItems}</List>
                    </Drawer>
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Route exact path="/" component={DailyDashboardArea} />
                        <Route path="/report/daily" component={DailyDashboardArea} />
                        <Route path="/report/monthly" component={MonthlyDashboardArea} />
                        <Route path="/new" component={NewOrdersArea} />
                        <Route path="/allocated" component={AllocatedOrdersArea} />
                        <Route path="/waiting" component={WaitingForApproveArea} />
                        <Route path="/approved" component={ApprovedOrdersArea} />
                        <Route path="/supplementRequest" component={SupplementRequestOrdersArea} />
                        <Route path="/rejected" component={RejectedOrdersArea} />
                        <Route path="/all" component={AllOrdersArea} />

                        <Route path="/detail/:code" component={OrderDetail} />
                       
                        <ToastProvider autoDismissTimeout={3000} placement="bottom-center">
                            <CreateOrder
                                open={this.state.showCreateOrder}
                                onClose={this.handleCloseCreateOrder}
                            />
                            <ImportOrder
                                open={this.state.showImportOrder}
                                onClose={this.handleCloseImportOrder}
                            />
                            <ChangePassword
                                open={this.state.showChangePassword}
                                onClose={this.handleCloseChangePassword}
                            />
                        </ToastProvider>
                    </main>
                </div>
            </Router>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);
