import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import 'react-image-gallery/styles/css/image-gallery.css';
import Typography from '@material-ui/core/Typography';
import DailySummary from './DailySummary';
import DailySummaryByProduct from './DailySummaryByProduct';

class DailyDashboard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Typography variant="h5" gutterBottom component="h2" style={{ marginTop: 10 }}>
                    Tổng hợp ngày
                </Typography>
                <DailySummary />
                {/* <Typography variant="h5" gutterBottom component="h2" style={{ marginTop: 30 }}>
                    Chi tiết theo sản phẩm
                </Typography> */}
                <DailySummaryByProduct />
            </div>
        );
    }
}

const styles = theme => ({});
export default withStyles(styles)(DailyDashboard);
