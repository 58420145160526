import ImageGallery from 'react-image-gallery';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import cs from '../../const.js';
import 'react-image-gallery/styles/css/image-gallery.css';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import path from 'path';
import './OrderDocList-Video.css';
import { Player } from 'video-react';

const URL = cs.BaseURL + '/order/doc/list';
const docFolderURL = cs.BaseURL + '/order/doc/folder-list';

class OrderDocList extends React.Component {
    constructor(props) {
        super(props);

        this.handleClickFolder = this.handleClickFolder.bind(this);
        this.state = {
            images: [],
            folders: [],
            orderCode: this.props.orderCode,
            selectedFolder: null
        };
        this.loadFolder();
    }
    loadFolder() {
        const { orderCode } = this.state;
        let queryString = `${docFolderURL}?flat_query=true&order_code=${orderCode}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-merchant-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({ folders: data.data, loading: false });
                    if (data.data instanceof Array && data.data.length > 0)
                        this.setState({ folder: data.data[0] }, () => {
                            this.loadData();
                        });

                    this.lastQuery = queryString;
                } else {
                    this.setState({
                        folders: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => this.setState({ loading: false }));
    }

    queryString() {
        const { orderCode, selectedFolder } = this.state;
        if (!selectedFolder) return null;

        let queryString = `${URL}?order_code=${orderCode}&doc_type_name=${selectedFolder.name}`;
        return queryString;
    }

    loadData() {
        const queryString = this.queryString();

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-merchant-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    var newImgs = [];

                    for (var i = 0; i < data.data.length; i++) {
                        var filename = data.data[i].filename;
                        if (String(filename).startsWith('aidriven/data'))
                            filename = String(filename).substr(
                                String('aidriven/data').length,
                                String(filename).length - String('aidriven/data').length
                            );

                        if (data.data[i].filetype == 'image') {
                            newImgs.push({
                                original:
                                    'https://media.aidriven.goldengate.biz.vn/images/' + filename, //+ '_1024',
                                thumbnail:
                                    'https://media.aidriven.goldengate.biz.vn/images/' +
                                    filename +
                                    '_350'
                            });
                        } else if (data.data[i].filetype == 'video') {
                            newImgs.push({
                                original:
                                    'https://media.aidriven.goldengate.biz.vn/video/' +
                                    path.basename(filename),
                                thumbnail:
                                    'https://media.aidriven.goldengate.biz.vn/images/' +
                                    filename +
                                    '_thumbnail',
                                renderItem: this._renderVideo.bind(this)
                            });
                        }
                    }

                    this.setState({ images: newImgs, loading: false });
                } else {
                    this.setState({
                        rows: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => this.setState({ loading: false }));
    }
    handleClickFolder = (folder) => {
        this.setState({ selectedFolder: folder }, () => {
            this.loadData();
        });
    };

    _renderVideo(item) {
        return (
            <div>
                <Player>
                    <source src={item.original} />
                </Player>
            </div>
        );
    }

    render() {
        const { folders, images, selectedFolder } = this.state;
        const { classes } = this.props;
        return (
            <div>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Grid
                        item
                        xs={2}
                        container
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                        spacing={1}
                    >
                        {folders.map((folder) => (
                            <Grid item className={classes.folderItemContainer}>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    className={
                                        selectedFolder && folder.name == selectedFolder.name
                                            ? classes.folderSelectedPanel
                                            : classes.folderPanel
                                    }
                                    onClick={(event) => this.handleClickFolder(folder)}
                                >
                                    <Typography variant="button" className={classes.folderText}>
                                        {folder.display}{' '}
                                        {folder.qty > 0 ? '(' + folder.qty + ')' : ''}
                                    </Typography>
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={10}>
                        {images && images.length > 0 ? (
                            <ImageGallery
                                items={images}
                                thumbnailPosition="right"
                                lazyLoad={true}
                                showPlayButton={false}
                                defaultImage="https://media.aidriven.goldengate.biz.vn/images/default_image_gallery.png"
                            />
                        ) : (
                            <div className={classes.noImageText}>
                                <Typography fullWidth variant="subtitle1">
                                    Chưa có tài liệu nào !
                                </Typography>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const styles = (theme) => ({
    info_container: {
        flexGrow: 1
    },
    folderItemContainer: {
        textAlign: 'left'
    },
    folderPanel: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 3,
        marginBottom: 3,
        borderRadius: 2,
        width: '200px',
        backgroundColor: 'bisque',
        paddingLeft: '3px',
        paddingRight: '3px',
        justifyContent: 'normal !important'
    },
    folderSelectedPanel: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 3,
        marginBottom: 3,
        borderRadius: 2,
        width: '200px',
        backgroundColor: 'burlywood',
        paddingLeft: '3px',
        paddingRight: '3px',
        justifyContent: 'normal !important'
    },
    folderText: {
        paddingLeft: 5,
        paddingRight: 5,
        textAlign: 'left',
        fontWeight: 550,
        fontSize: 10
    },
    noImageText: {
        width: '100%',
        marginTop: 150,
        textAlign: 'center'
    }
});
export default withStyles(styles)(OrderDocList);
