import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormData from 'form-data';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withToastManager } from 'react-toast-notifications';
import { DropzoneArea } from 'material-ui-dropzone';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import cs from '../../const.js';
import format from 'string-format';
import moment from 'moment-timezone';

const productsURL = cs.BaseURL + '/product/list';
const importOrderURL = cs.BaseURL + '/order/import';
const verifyOrderURL = cs.BaseURL + '/order/verify_before_import';

function formatErrors(errors) {
    errors.map((row, i) => {
        row.content = '';
        if (row && row.code == cs.erInvalidRequest.code)
            row.content = format('Dòng {0} : File không hợp lệ.', row.rowNo + 1);
        else if (row && row.code == cs.erMerchantIsLocked.code)
            row.content = format('Dòng {0} : Tài khoản đối tác đã bị khóa .', row.rowNo + 1);
        else if (row && row.code == cs.erProductNotExisted.code)
            row.content = format('Dòng {0} : Mã sản phẩm không tồn tại.', row.rowNo + 1);
        else if (row && row.code == cs.erProductInvalid.code)
            row.content = format('Dòng {0} : Mã sản phẩm không hợp lệ.', row.rowNo + 1);
        else if (row && row.code == cs.erProductIsLocked.code)
            row.content = format('Dòng {0} : Mã sản phẩm đã bị khóa .', row.rowNo + 1);
        else if (row && row.code == cs.erProductCodeCannotEmpty.code)
            row.content = format('Dòng {0} : Mã sản phẩm không được trống.', row.rowNo + 1);
        else if (row && row.code == cs.erPhoneNumberCannotEmpty.code)
            row.content = format(
                'Dòng {0} : Số điện thoại khách hàng không được trống.',
                row.rowNo + 1
            );
        else if (row && row.code == cs.erCustomerNameCannotEmpty.code)
            row.content = format('Dòng {0} : Tên khách hàng không được trống.', row.rowNo + 1);
        else if (row && row.code == cs.erCustomerGenderCannotEmpty.code)
            row.content = format(
                'Dòng {0} : Giới tính khách hàng không được trống.',
                row.rowNo + 1
            );
        else if (row && row.code == cs.erStateCannotEmpty.code)
            row.content = format('Dòng {0} : Tỉnh/thành phố không được trống.', row.rowNo + 1);
        else if (row && row.code == cs.erDistrictCannotEmpty.code)
            row.content = format('Dòng {0} : Quận/huyện không được trống.', row.rowNo + 1);
        else if (row && row.code == cs.erInvalidState.code)
            row.content = format('Dòng {0} : Tỉnh/thành phố không hợp lệ.', row.rowNo + 1);
        else if (row && row.code == cs.erInvalidDistrict.code)
            row.content = format('Dòng {0} : Quận/huyện không hợp lệ.', row.rowNo + 1);
        else if (row && row.code == cs.erOrderRefRequired.code)
            row.content = format(
                'Dòng {0} : Mã tham chiếu (mc_order_ref) không được trống.',
                row.rowNo + 1
            );
        else if (row && row.code == cs.erOrderRefIsExisted.code)
            row.content = format(
                'Dòng {0} : Mã tham chiếu (mc_order_ref)  đã tồn tại ở hồ sơ cũ.',
                row.rowNo + 1
            );
        else if (row && row.code == cs.erCannotCreateObject.code)
            row.content = format('Dòng {0} : Không thể tạo hồ sơ.', row.rowNo + 1);
        else if (row && row.code == cs.erOrderCusPhoneIsExisted.code)
            row.content = format('Dòng {0} : Số điện thoại đã tồn tại.', row.rowNo + 1);
        else if (row && row.code == cs.erOrderCusNationalIDIsExisted.code)
            row.content = format('Dòng {0} : CMND đã tồn tại.', row.rowNo + 1);
        else if (row && row.code == cs.erPhoneNumberInvalid.code)
            row.content = format('Dòng {0} : Số điện thoại không hợp lệ.', row.rowNo + 1);
        else if (row && row.code == cs.erNationalIdInvalid.code)
            row.content = format('Dòng {0} : CMND không hợp lệ.', row.rowNo + 1);
        else row.content = format('Dòng {0} : {1}.', row.rowNo + 1, row.error);
    });
    return errors;
}

class ImportOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file: null,
            products: null,
            errors: [],
            isProcessing: false,
            isFileValid: false,
            isFileImportProcessed: false,
            isFileImported: false
        };
        this.loadProducts = this.loadProducts.bind(this);
        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.handleDialogDisagree = this.handleDialogDisagree.bind(this);
        this.handleDropZoneChange = this.handleDropZoneChange.bind(this);

        this.handleClose = this.handleClose.bind(this);
        this.resetState();
    }

    resetState() {
        this.setState({
            file: null,
            errors: [],
            isProcessing: false,
            isFileValid: false,
            isFileImportProcessed: false,
            isFileImported: false
        });
    }

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    loadProducts() {
        let queryString = `${productsURL}`;
        var lastData = JSON.parse(localStorage.getItem(cs.System_Code + '-merchant-products'));
        if (
            lastData &&
            queryString === lastData.query &&
            lastData.query_at &&
            parseInt(lastData.query_at) + 60 * 60 * 1 > moment().unix()
        ) {
            this.setState({ products: lastData.products });
            return;
        }

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-merchant-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    this.setState({
                        products: data.data
                    });

                    localStorage.setItem(
                        cs.System_Code + '-merchant-products',
                        JSON.stringify({
                            products: data.data,
                            query: queryString,
                            query_at: moment().unix()
                        })
                    );
                } else {
                    this.setState({
                        products: []
                    });
                    localStorage.setItem(cs.System_Code + '-merchant-products', null);
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                localStorage.setItem(cs.System_Code + '-merchant-products', null);
                this.setState({
                    products: []
                });
            });
    }
    handleDialogAgree = () => {
        if (!this.state.mc_product_code || !this.state.file) {
            window.alert('Vui lòng nhập đầy đủ thông tin !');
            return;
        }
        this.setState({
            isProcessing: true,
            isFileImportProcessed: false,
            isFileImported: false,
            errors: []
        });

        let queryString = `${importOrderURL}`;

        const formData = new FormData();
        formData.append('excel-file', this.state.file);
        formData.append('mc_product_code', this.state.mc_product_code);

        fetch(queryString, {
            method: 'POST',
            body: formData,
            headers: { Authorization: localStorage.getItem(cs.System_Code + '-merchant-token') }
        })
            .then(response => response.json())
            .then(data => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                var data = data.data;

                var isFileImported = false;
                if (
                    data &&
                    data.requested_qty > 0 &&
                    data.created_qty > 0 &&
                    data.requested_qty == data.created_qty
                )
                    isFileImported = true;

                this.setState({
                    isProcessing: false,
                    isFileImported: isFileImported,
                    errors: formatErrors(data.errors)
                });
                if (isFileImported) {
                    this.props.toastManager.add('Tạo hồ sơ thành công !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });

                    this.handleClose();
                }
            })
            .catch(() => {
                this.resetState();
            });
    };

    handleDialogDisagree = () => {
        this.handleClose();
    };

    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {}
        );
    };

    handleDropZoneChange(files) {
        if (!this.state.mc_product_code) {
            window.alert('Vui lòng nhập chọn sản phẩm !');
            return;
        }
        if (files && files instanceof Array && files.length > 0) {
            this.setState({
                file: files[0],
                isProcessing: true,
                isFileValid: false,
                isFileImportProcessed: false,
                isFileImported: false,
                errors: []
            });

            var file = files[0];

            let queryString = `${verifyOrderURL}`;

            const formData = new FormData();
            formData.append('excel-file', file);
            formData.append('mc_product_code', this.state.mc_product_code);

            var that = this;
            fetch(queryString, {
                method: 'POST',
                body: formData,
                headers: { Authorization: localStorage.getItem(cs.System_Code + '-merchant-token') }
            })
                .then(response => response.json())
                .then(data => {
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        window.location.reload();
                    }

                    var data = data.data;
                    var isFileValid = false;
                    if (
                        data &&
                        data.requested_qty > 0 &&
                        data.verified_qty > 0 &&
                        data.requested_qty == data.verified_qty
                    )
                        isFileValid = true;
                    that.setState({
                        isProcessing: false,
                        isFileValid: isFileValid,
                        errors: formatErrors(data.errors)
                    });
                })
                .catch(() => {
                    that.resetState();
                });
        } else this.resetState();
    }

    render() {
        const { classes } = this.props;

        if (this.props.open != this.state.open && this.props.open) {
            this.state.open = this.props.open;
        }
        var templateURL =
            'https://media.aidriven.goldengate.biz.vn/images/import_order_template_' +
            this.state.mc_product_code +
            '.zip';

        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="create_order_form-dialog-title">
                        Tạo hồ sơ hàng loạt
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Vui lòng liên hệ Administrator để được hướng dẫn.
                        </DialogContentText>{' '}
                        <Grid
                            container
                            direction="row"
                            justify="fixed-start"
                            alignItems="stretch"
                            spacing={20}
                            className={classes.downloadContainer}
                            gutterBottom
                        >
                            <FormControl fullWidth>
                                <InputLabel htmlFor="productId">Sản phẩm (*)</InputLabel>
                                <Select
                                    value={this.state.mc_product_code}
                                    onChange={this.handleChange('mc_product_code')}
                                    inputProps={{
                                        name: 'product',
                                        id: 'productId'
                                    }}
                                >
                                    {this.state.products &&
                                        this.state.products.map(pro => (
                                            <MenuItem value={pro.code}>
                                                {pro.name} - {pro.mc_code}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {this.state.mc_product_code && (
                            <Grid
                                container
                                direction="row"
                                justify="fixed-start"
                                alignItems="stretch"
                                spacing={20}
                                className={classes.downloadContainer}
                                gutterBottom
                            >
                                <Grid item xs={7}>
                                    <Typography variant="body1">Tải file mẫu :</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <a
                                        download="import-orders-template.zip"
                                        target="_blank"
                                        href={templateURL}
                                        style={{ fontSize: 13, marginTop: 2 }}
                                    >
                                        import_orders.zip
                                    </a>
                                </Grid>
                            </Grid>
                        )}
                        <Grid
                            container
                            direction="column"
                            justify="space-between"
                            alignItems="stretch"
                            spacing={10}
                            className={classes.gridContainer}
                        >
                            {this.state.isProcessing && (
                                <div style={{ height: 150 }}>
                                    <CircularProgress
                                        style={{
                                            position: 'relative',
                                            left: '45%',
                                            top: '70px'
                                        }}
                                    />
                                </div>
                            )}
                            {!this.state.isProcessing && (
                                <Grid item gutterBottom>
                                    <DropzoneArea
                                        acceptedFiles={[
                                            'application/vnd.ms-excel',
                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                        ]}
                                        dropzoneText={
                                            this.state.file && this.state.file.name
                                                ? this.state.file.name
                                                : 'Upload file exel theo mẫu'
                                        }
                                        maxFileSize={50000000}
                                        filesLimit={1}
                                        showAlerts={false}
                                        showPreviewsInDropzone={false}
                                        onChange={this.handleDropZoneChange}
                                        dropZoneClass={classes.dropZone}
                                    />
                                </Grid>
                            )}
                            <Grid item gutterBottom className={classes.statusItem}>
                                {this.state.file &&
                                    !this.state.isProcessing &&
                                    this.state.isFileValid && (
                                        <Typography variant="body1">File hợp lệ !</Typography>
                                    )}
                                {this.state.file &&
                                    !this.state.isProcessing &&
                                    !this.state.isFileValid && (
                                        <Typography variant="body1">File không hợp lệ !</Typography>
                                    )}
                                {this.state.file &&
                                    !this.state.isProcessing &&
                                    this.state.isFileImportProcessed &&
                                    this.state.isFileImported && (
                                        <Typography variant="body1">
                                            File import thành công !
                                        </Typography>
                                    )}
                                {this.state.file &&
                                    !this.state.isProcessing &&
                                    this.state.isFileImportProcessed &&
                                    !this.state.isFileImported && (
                                        <Typography variant="body1">
                                            File import thất bại !
                                        </Typography>
                                    )}
                            </Grid>
                            <Grid item>
                                <div>
                                    <List>
                                        {this.state.errors.map((er, i) => {
                                            return (
                                                <ListItem
                                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                                >
                                                    <ListItemIcon
                                                        style={{ marginLeft: 0, marginRight: 0 }}
                                                    >
                                                        <Icon
                                                            color="error"
                                                            style={{
                                                                marginLeft: 0,
                                                                marginRight: 0,
                                                                fontSize: 26
                                                            }}
                                                        >
                                                            error_outline
                                                        </Icon>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        classes={{
                                                            primary: { color: 'primary' },
                                                            secondary: { color: 'primary' }
                                                        }}
                                                        secondary={er.content}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {(!this.state.file || !this.state.isProcessing) && (
                            <Button onClick={this.handleDialogDisagree} color="primary">
                                {this.state.isFileImportProcessed ? 'Hoàn tất' : 'Đóng'}
                            </Button>
                        )}
                        {this.state.file &&
                            !this.state.isProcessing &&
                            this.state.isFileValid &&
                            !this.state.isFileImportProcessed &&
                            !this.state.isFileImported && (
                                <Button onClick={this.handleDialogAgree} color="primary">
                                    Tạo hồ sơ
                                </Button>
                            )}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ImportOrder.propTypes = {
    classes: PropTypes.object.isRequired
};
const styles = theme => ({
    gridContainer: {
        paddingTop: 10
    },
    downloadContainer: {
        paddingTop: 10
    },
    dropZone: {
        marginTop: 10,
        minHeight: 200
    },
    erItem: {
        secondary: 'pink'
    },
    statusItem: {
        marginTop: 10
    }
});
export default withToastManager(withStyles(styles)(ImportOrder));
