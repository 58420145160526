import ImageGallery from 'react-image-gallery';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import cs from '../../const.js';
import 'react-image-gallery/styles/css/image-gallery.css';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

var format = require('string-format');
const URL = cs.BaseURL + '/order/comment/list';
const sendCommentURL = cs.BaseURL + '/order/comment/create';

function formatValue(value) {
    if (value && value.created_at)
        value.created_at = moment
            .unix(value.created_at)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM/YYYY HH:mm');

    // if (value && value.content) value.content = value.content.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return value;
}

class OrderComments extends React.Component {
    constructor(props) {
        super(props);

        this.state = { comment: '', comments: [], orderCode: this.props.orderCode };

        this.handleSend = this.handleSend.bind(this);
    }
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    queryString() {
        const { orderCode } = this.state;
        let queryString = `${URL}?order_code=${orderCode}`;
        return queryString;
    }

    loadData() {
        const queryString = this.queryString();
        if (queryString === this.lastQuery) {
            this.setState({ loading: false });
            return;
        }
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-merchant-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    var comments = [];

                    for (var i = 0; i < data.data.length; i++) {
                        comments.push(formatValue(data.data[i]));
                    }

                    this.setState({ comments: comments, loading: false });
                } else {
                    this.setState({
                        rows: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => this.setState({ loading: false }));
    }

    handleTextCommentChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };

    handleSend() {
        const { orderCode, comment } = this.state;
        let queryString = `${sendCommentURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode,
                content: comment
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-merchant-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ comment: '' }, () => {
                    this.loadData();
                });
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    sessionStorage.clear();
                    window.location.reload();
                }
            })
            .catch(() =>
                this.setState({ comment: '' }, () => {
                    this.loadData();
                })
            );
    }
    render() {
        const { comments } = this.state;
        const { classes } = this.props;
        return (
            <div>
                <List className={classes.root}>
                    <ListItem
                        style={{
                            flexGrow: 1,
                            width: '100%',
                            display: 'block'
                        }}
                    >
                        {comments.map(item => (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'inline-block'
                                }}
                            >
                                <Grid container direction="row" justify="first-start" alignItems="stretch" spacing={16}>
                                    <Grid item xs={2}>
                                        <Grid container direction="column" justify="first-start" alignItems="center" spacing={8}>
                                            <Grid item xs={4}>
                                                {item.author_type == 'Agent' ? (
                                                    <Avatar className={classes.purpleAvatar}>AG</Avatar>
                                                ) : (
                                                    ''
                                                )}
                                                {item.author_type == 'Merchant' ? (
                                                    <Avatar className={classes.orangeAvatar}>MC</Avatar>
                                                ) : (
                                                    ''
                                                )}
                                                {item.author_type == 'OP' ? <Avatar className={classes.avatar}>eF</Avatar> : ''}
                                            </Grid>{' '}
                                            <Grid item xs={10}>
                                                <ListItemText
                                                    primary={
                                                        <Typography component="body2">
                                                            {item.author_type == 'Agent' ? (item.author_fullname ? item.author_fullname : item.author_name) : ''}
                                                            {item.author_type == 'Merchant' ? (item.author_fullname ? item.author_fullname : item.author_name) : ''}
                                                            {item.author_type == 'OP' ? cs.System_Name : ''}
                                                        </Typography>
                                                    }
                                                    style={{ alignItems: 'center', padding: 0 }}
                                                />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <ListItemText
                                                    primary={<Typography variant="caption">{item.created_at}</Typography>}
                                                    style={{ alignItems: 'center', padding: 0 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={10}>
                                        <ListItemText
                                            primary={<Typography component="body2">{item.content}</Typography>}
                                            style={{
                                                alignContent: 'left',
                                                marginTop: 5,
                                                whiteSpace: 'pre-line'
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Divider style={{ margin: '10px 0 10px 0' }} />
                            </div>
                        ))}
                    </ListItem>
                </List>
                <Grid
                    container
                    direction="row"
                    justify="stretch"
                    alignItems="stretch"
                    spacing={16}
                    style={{
                        marginLeft: 20
                    }}
                >
                    <Grid item xs={9}>
                        <TextField
                            multiline
                            rows="4"
                            rowsMax="15"
                            id="send_comment"
                            value={this.state.comment}
                            onChange={this.handleTextCommentChange('comment')}
                            label="Bình luận"
                            type="name"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            className={classes.send_button}
                            variant="contained"
                            color="primary"
                            onClick={this.handleSend}
                        >
                            GỬI
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const styles = theme => ({
    inner_container: {
        padding: 20
    },
    avatar: {
        margin: 0,
        fontSize: '14px'
    },
    orangeAvatar: {
        margin: 0,
        color: '#fff',
        backgroundColor: deepOrange[500],
        fontSize: '14px'
    },
    purpleAvatar: {
        margin: 0,
        color: '#fff',
        backgroundColor: deepPurple[500],
        fontSize: '14px'
    }
});
export default withStyles(styles)(OrderComments);
