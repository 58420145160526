import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { Chart, ArgumentAxis, ValueAxis, BarSeries, Legend } from '@devexpress/dx-react-chart-material-ui';
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart';
import cs from '../../const.js';

import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import moment from 'moment-timezone';

import React from 'react';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { blue } from '@material-ui/core/colors';

const URL = cs.BaseURL + '/report/daily_summary';

function formatValue(value) {
    if (value && value.report_date) {
        value.report_date = moment
            .unix(value.report_date)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM');
    }
    return value;
}

const rootStyles = {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row'
};
const LegendRoot = props => <Legend.Root {...props} style={rootStyles} />;

const defaultLabelStyles = {
    marginLeft: '10px',
    whiteSpace: 'nowrap',
    fontSize: '14px'
};
const LegendLabel = props => (
    <div {...props} style={defaultLabelStyles}>
        {props.text}
    </div>
);

class DailySummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: [],

            loading: true
        };
    }
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    queryString() {
        let search_start_date = moment()
            .tz('Asia/Ho_Chi_Minh')
            .subtract(14, 'day')
            .startOf('day')
            .unix();

        let search_end_date = moment()
            .tz('Asia/Ho_Chi_Minh')
            .endOf('day')
            .unix();

        let queryString = `${URL}?new=true&orderby=report_date&report_date_from=${search_start_date}&report_date_to=${search_end_date}`;

        return queryString;
    }
    loadData = conditions => {
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-merchant-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    // console.log(JSON.stringify(data.data))
                    this.setState({
                        rows: data.data,
                        totalCount: data.count,
                        loading: false
                    });

                    // var rows = [];
                    // for (var i = 0; i < 10; i++) {
                    //     var obj = {};
                    //     obj.report_date = moment()
                    //         .add(i, 'month')
                    //         .tz('Asia/Ho_Chi_Minh')
                    //         .format('DD/MM/YYYY');
                    //     obj.mc_approved_qty = i * 47;
                    //     obj.agent_submitted_qty = i * 73;
                    //     rows.push(obj);
                    // }
                    // this.setState({
                    //     rows: rows,
                    //     totalCount: rows.length,
                    //     loading: false
                    // });
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                }
            })
            .catch(() => this.setState({ loading: false }));
    };

    render() {
        const { classes } = this.props;

        const { rows } = this.state;

        return (
            <div>
                <Chart data={rows} height="300">
                    <ArgumentAxis />
                    <ArgumentScale />
                    <ValueAxis /> 
                    <Legend position="bottom" rootComponent={LegendRoot} labelComponent={LegendLabel} />
                    <BarSeries argumentField="report_date" valueField="created_qty" name="Tiếp nhận" />
                    <BarSeries argumentField="report_date" valueField="agent_submitted_qty" name="Hoàn tất" />
                    <BarSeries argumentField="report_date" valueField="mc_approved_qty" name="Đã Duyệt" />
                    <Stack />
                </Chart>
            </div>
        );
    }
}

DailySummary.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_header: {
        // backgroundColor: blue
    }
});

export default withStyles(styles)(DailySummary);
